<div class="container-fluid text-center">
  <div class="row pt-3 ml-3">
    <div class="col d-flex justify-content-center">
      <h1>Statistics</h1>
    </div>
  </div>
  <div class="row pt-2 justify-content-md-center">
    <div class="col-md-12 col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center">
      <mat-card class="overview-card events-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.infinity" class="m-auto"></fa-icon>
              Total
            </mat-card-title>
            <mat-card-subtitle>Submissions</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="statistics$ | async as statistics else defaultCount">
          {{statistics.submission_stats.total_submissions}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center">
      <mat-card class="overview-card user-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.open" class="m-auto"></fa-icon>
              Open
            </mat-card-title>
            <mat-card-subtitle>Submissions</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="statistics$ | async as statistics else defaultCount">
          {{statistics.submission_stats.open_submissions}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center">
      <mat-card class="overview-card user-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.locked" class="m-auto"></fa-icon>
              Closed
            </mat-card-title>
            <mat-card-subtitle>Submissions</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="statistics$ | async as statistics else defaultCount" matTooltip="{{getClosedCountInfo(statistics.submission_stats)}}" matTooltipClass="mat-tooltip" matTooltipPosition="right">
          {{statistics.submission_stats.closed_submissions + statistics.submission_stats.done_submissions + statistics.submission_stats.denied_submissions}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-container *ngIf="submissions$ | async as submissions">
    <div class="row pt-5">
      <div class="col-12 d-flex justify-content-center">
        <h1>Most Popular Submissions</h1>
      </div>
    </div>
    <div class="row justify-content-center pt-2">
      <div class="col-12 d-flex justify-content-center">
        <mat-table [dataSource]="submissions.most_voted_submissions" class="overview-card mat-elevation-z8">
          <!-- Submission ID -->
          <ng-container matColumnDef="submissionId">
            <mat-header-cell *matHeaderCellDef> Submission ID </mat-header-cell>
            <mat-cell *matCellDef="let submission">
              <a href='https://discordapp.com/channels/{{ submission.server_id }}/{{ submission.channel_id }}/{{ submission.message_id }}'>
                {{ submission.submission_id }}
              </a>
            </mat-cell>
          </ng-container>
          <!-- Title -->
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
            <mat-cell *matCellDef="let submission"> {{ submission.title }} </mat-cell>
          </ng-container>
          <!-- Creation Date -->
          <ng-container matColumnDef="creationDate">
            <mat-header-cell *matHeaderCellDef> Creation Date </mat-header-cell>
            <mat-cell *matCellDef="let submission"> {{ submission.created_at_date }} </mat-cell>
          </ng-container>
          <!-- Upvotes -->
          <ng-container matColumnDef="upvotes">
            <mat-header-cell *matHeaderCellDef> Upvotes </mat-header-cell>
            <mat-cell *matCellDef="let submission"> {{ submission.upvote_count }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['submissionId', 'title', 'creationDate', 'upvotes']"></mat-header-row>
          <mat-row *matRowDef="let row; let even = even; columns: ['submissionId', 'title', 'creationDate', 'upvotes']"></mat-row>
        </mat-table>
      </div>
    </div>
    <div class="row justify-content-center pt-2">
      <div class="col-12 d-flex justify-content-center">
        <mat-card-content *ngIf="statistics$ | async as statistics else defaultCount">
          <mat-paginator
            [length]="statistics.submission_stats.open_submissions"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            [pageIndex]="currentPage - 1"
            (page)="onPageChange($event)"
          ></mat-paginator>
        </mat-card-content>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #defaultCount>
  <mat-card-content>0</mat-card-content>
</ng-template>

