<div class="container-fluid">
  <div class="row pt-3 ml-3">
    <div class="col d-flex justify-content-center">
      <div *ngIf="channelInfo$ | async as channelInfo">
        <h1>Channel: <i>{{ channelInfo.collection_name }}</i></h1>
      </div>
    </div>
  </div>

  <div *ngIf="guild && channelInfo$ | async as channelInfo" class="pb-3 pt-2">
    <div *ngIf="!(guild.owner || channelInfo.has_mod_role)" class="alert alert-warning h5">
      &#x26A0; Only users with the <a href="https://docs.submitty.app/getting-started/terms#moderator-role"
        target="_blank">moderator role</a>,
      or higher permissions, can change those settings!
    </div>
  </div>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <!-- General Settings -->
    <mat-card class="mb-3">
      <mat-card-header>
        <mat-card-title>
          <h4>General Settings</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="groupNameInput">
          <mat-form-field class="full-width-container">
            <mat-label>Submission Group Name</mat-label>
            <input matInput #groupName minlength="1" maxlength="100" placeholder="Feature Requests"
              [value]="channelInfo.collection_name" [disabled]="!(guild.owner || channelInfo.has_mod_role)"
              (change)="groupName.value.length >= 1 && setSubmissionGroupName($event.target)" required>
            <mat-hint align="end">{{groupName.value.length}} / 100</mat-hint>
          </mat-form-field>
        </form>
        <form class="modRoleSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="modRoleSelection">Moderator Role</mat-label>
            <mat-select [(ngModel)]="channelInfo.mod_role" name="modRole" id="modRoleSelection"
              [disabled]="!(guild.owner || channelInfo.has_mod_role)" (selectionChange)="setModRole($event)">
              <ng-container *ngFor="let role of channelInfo.roles">
                <mat-option [value]="role.id" [selected]="role.id === channelInfo.mod_role">
                  {{ role.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint align="end">Users with that role have full permission in this submission group.</mat-hint>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>
    <!-- Review Settings -->
    <mat-card class="mb-3">
      <mat-card-header>
        <mat-card-title>
          <h4>Review Channel Settings</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="reviewChannelSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="reviewChannelSelection">Review channel</mat-label>
            <mat-select [(ngModel)]="channelInfo.review_channel.id" name="reviewChannel" id="reviewChannelSelection"
              [disabled]="!(guild.owner || channelInfo.has_mod_role)" (selectionChange)="setReviewChannel($event)">
              <ng-container *ngFor="let channel of channelInfo.channels">
                <mat-option [value]="channel.id" [selected]="channel.id === channelInfo.review_channel.id">
                  {{ channel.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint align="end">If set, new submissions require an approval before being published.</mat-hint>
          </mat-form-field>
        </form>
        <div class="toggleOnlyThreadCreator">
          <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_mod_role)" [checked]="channelInfo.only_thread_creator"
            (change)="toggleOnlyThreadCreator()">
            If set, only the feedback thread initiator will be added to the thread. Else, all users with the moderator role are added.
            <small class="form-text text-muted">/channel review edit</small>
          </mat-slide-toggle>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- Submission Channel Settings -->
    <mat-card class="mb-3">
      <mat-card-header>
        <mat-card-title>
          <h4>Submission Channel Settings</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="submissionChannelSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="submissionChannelSelection">Submission channel</mat-label>
            <mat-select [(ngModel)]="channelInfo.submission_channel.id" name="submissionChannel"
              id="submissionChannelSelection" disabled>
              <ng-container *ngFor="let channel of channelInfo.channels">
                <mat-option [value]="channel.id" [selected]="channel.id === channelInfo.submission_channel.id">
                  {{ channel.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint align="end">Submission channel can not be changed</mat-hint>
          </mat-form-field>
        </form>
        <form class="voteRoleSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="voteRoleSelection">Vote Role</mat-label>
            <mat-select [(ngModel)]="channelInfo.vote_role" name="voteRole" id="voteRoleSelection"
              [disabled]="!(guild.owner || channelInfo.has_mod_role)" (selectionChange)="setVoteRole($event)">
              <ng-container *ngFor="let role of channelInfo.roles">
                <ng-container *ngIf="role.id !== ''">
                  <!-- Exclude empty role because backend defaults to @everyone on empty -->
                  <mat-option [value]="role.id" [selected]="role.id === channelInfo.vote_role">
                    {{ role.name }}
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-select>
            <mat-hint align="end">Users with this role are allowed to create and vote on submissions.</mat-hint>
          </mat-form-field>
        </form>
        <form class="pingRoleSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="pingRoleSelection">Ping Role</mat-label>
            <mat-select [(ngModel)]="channelInfo.ping_role" name="pingRole" id="pingRoleSelection"
              [disabled]="!(guild.owner || channelInfo.has_mod_role)" (selectionChange)="setPingRole($event)">
              <ng-container *ngFor="let role of channelInfo.roles">
                <mat-option [value]="role.id" [selected]="role.id === channelInfo.ping_role">
                  {{ role.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint align="end">Users with this role get pings on new submissions.</mat-hint>
          </mat-form-field>
        </form>
        <div class="toggleThreadChoices">
          <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_mod_role)" [checked]="channelInfo.thread"
            (change)="toggleThread()">
            Attach threads to open submissions
            <small class="form-text text-muted">/channel submission edit</small>
          </mat-slide-toggle>
        </div>
        <form class="finishBehaviorSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="finishBehaviorSelection">Submission Finish Behavior</mat-label>
            <mat-select [(ngModel)]="channelInfo.finish_behavior" name="finishBehaviorSelect"
              id="finishBehaviorSelection" [disabled]="!(guild.is_admin || channelInfo.has_mod_role)"
              (selectionChange)="setFinishBehavior($event)">
              <mat-option value="1"><b>Delete</b> the submission in the <code>submission</code> channel and
                <b>recreate</b> it in the <code>closed</code> channel (default)</mat-option>
              <mat-option value="2"><b>Close</b> the submission in the <code>submission</code> channel and
                <b>duplicate</b> it in the <code>closed</code> channel</mat-option>
            </mat-select>
            <mat-hint align="end">NOTE: Creation in <code>closed</code> channel only works if <code>closed</code>
              channel is set!</mat-hint>
          </mat-form-field>
        </form>
        <mat-accordion multi>
          <ng-container *ngIf="customEmojis$ | async as customEmojis">
            <mat-expansion-panel expanded class="expansion-pannel-emojis mt-4">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Submission Vote Emojis
                    <mat-error *ngIf="!(guild.premium_lv >= 1)" class="pl-1 warning-emoji"
                      matTooltip="This is a premium feature">
                      <a [routerLink]="['/guilds', guildId, 'premium']"
                        style="text-decoration: none; color: inherit;">⚠️</a>
                    </mat-error>
                  </h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row pb-3">
                <div class="col-8 col-sm-5 col-lg-3 col-xl-2 align-self-center">
                  <span class="">Upvote Emoji:</span>
                </div>
                <div class="col-4 col-sm-4">
                  <app-emoji-picker-button class="emoji-picker-component" [customEmojis]="customEmojis"
                    [disabled]="!has_premium_and_is_organizer" [defaultEmoji]="channelInfo.vote_emojis.up_vote"
                    [resetSignal]="resetSignal"
                    (emojiSelected)="handleEmojiSelection('submission_channel up_vote set', $event)">
                  </app-emoji-picker-button>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col-8 col-sm-5 col-lg-3 col-xl-2 align-self-center ">
                  <span class="">Don't Care Emoji:</span>
                </div>
                <div class="col-4 col-sm-4 ">
                  <app-emoji-picker-button class="emoji-picker-component" [customEmojis]="customEmojis"
                    [disabled]="!has_premium_and_is_organizer" [defaultEmoji]="channelInfo.vote_emojis.don_t_care"
                    [resetSignal]="resetSignal"
                    (emojiSelected)="handleEmojiSelection('submission_channel don_t_care set', $event)">
                  </app-emoji-picker-button>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col-8 col-sm-5 col-lg-3 col-xl-2 align-self-center">
                  <span class="">Downvote Emoji:</span>
                </div>
                <div class="col-4 col-sm-4 ">
                  <app-emoji-picker-button class="emoji-picker-component" [customEmojis]="customEmojis"
                    [disabled]="!has_premium_and_is_organizer" [defaultEmoji]="channelInfo.vote_emojis.down_vote"
                    [resetSignal]="resetSignal"
                    (emojiSelected)="handleEmojiSelection('submission_channel down_vote set', $event)">
                  </app-emoji-picker-button>
                </div>
              </div>
              <mat-hint>NOTE: New emojis appear on new submissions or existing ones after the vote button is
                pressed.</mat-hint>
            </mat-expansion-panel>
          </ng-container>
          <mat-expansion-panel expanded class="expansion-pannel-colors">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h5>Submission Colors
                  <mat-error *ngIf="!(guild.premium_lv >= 1)" class="pl-1 warning-emoji"
                    matTooltip="Changing and adding ranges requires premium">
                    <a [routerLink]="['/guilds', guildId, 'premium']"
                      style="text-decoration: none; color: inherit;">⚠️</a>
                  </mat-error>
                </h5>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="colorForm" (ngSubmit)="onColorSubmit()">
              <div formArrayName="colors">
                <div *ngFor="let color of colors.controls; let i = index" [formGroupName]="i" class="color-row">
                  <div class="row">
                    <div class="col-5 pr-0">
                      <mat-form-field class="full-width-container">
                        <mat-label>Range Start</mat-label>
                        <input matInput type="number" formControlName="range_start" />
                        <mat-error *ngIf="color.get('range_start').hasError('required')">
                          Start range is required
                        </mat-error>
                        <mat-error *ngIf="color.get('range_start').errors?.max">Maximum range is
                          2147483647
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-5 pr-0">
                      <mat-form-field class="full-width-container">
                        <mat-label>Range End</mat-label>
                        <input matInput type="number" formControlName="range_end" />
                        <mat-error *ngIf="color.get('range_end').hasError('required')">
                          End range is required
                        </mat-error>
                        <mat-error *ngIf="color.get('range_end').hasError('rangeInvalid')">
                          Range end must be equal to or greater than start.
                        </mat-error>
                        <mat-error *ngIf="color.get('range_end').errors?.max">Maximum range is
                          2147483647
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-2 align-self-center">
                      <div class="full-width-container">
                        <ngx-colors class="mr-5" matSuffix formControlName="color" ngx-colors-trigger
                          colorPickerControls="no-alpha">
                        </ngx-colors>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button type="button" mat-raised-button color="secondary" [disabled]="!has_premium_and_is_organizer"
                    class="mr-3" (click)="addLimit()">
                    <fa-icon [icon]="faIcons.plus" class="m-auto"></fa-icon>
                    Add Limit
                  </button>
                  <button type="button" mat-raised-button color="primary" (click)="removeLimit()"
                    [disabled]="colors.length <= 1 || !has_premium_and_is_organizer">
                    <fa-icon [icon]="faIcons.minus" class="m-auto"></fa-icon>
                    Remove Limit
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <mat-error *ngIf="colorForm.hasError('overlap', 'colors')">
                    Overlapping ranges are not allowed
                  </mat-error>
                  <mat-warning *ngIf="hasGapWarning" class="text-warning">
                    Warning: There are gaps in the color ranges.<br />You can still submit, but <code>Submitty</code>
                    will take default colors for gaps.<br />
                  </mat-warning>
                  <button mat-raised-button color="primary" [disabled]="!checkColorChanges() || colorForm.invalid || !has_premium_and_is_organizer">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>
    <!-- Close Channel Settings -->
    <mat-card class="mb-3">
      <mat-card-header>
        <mat-card-title>
          <h4>Close Channel Settings</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="closeChannelSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="closeChannelSelection">Close channel</mat-label>
            <mat-select [(ngModel)]="channelInfo.closed_channel.id" name="closeChannel" id="closeChannelSelection"
              [disabled]="!(guild.owner || channelInfo.has_mod_role)" (selectionChange)="setCloseChannel($event)">
              <ng-container *ngFor="let channel of channelInfo.channels">
                <mat-option [value]="channel.id" [selected]="channel.id === channelInfo.closed_channel.id">
                  {{ channel.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint align="end">Submissions are sent to this channel on close/done.</mat-hint>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>
    <!-- Deny Channel Settings -->
    <mat-card class="mb-3">
      <mat-card-header>
        <mat-card-title>
          <h4>Deny Channel Settings</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="denyChannelSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="denyChannelSelection">Deny channel</mat-label>
            <mat-select [(ngModel)]="channelInfo.denied_channel.id" name="denyChannel" id="denyChannelSelection"
              [disabled]="!(guild.owner || channelInfo.has_mod_role)" (selectionChange)="setDenyChannel($event)">
              <ng-container *ngFor="let channel of channelInfo.channels">
                <mat-option [value]="channel.id" [selected]="channel.id === channelInfo.denied_channel.id">
                  {{ channel.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint align="end">Submissions are sent to this channel on deny.</mat-hint>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <!-- API Settings -->
  <ng-container *ngIf="guild && guild.is_admin && channelInfo$ | async as channelInfo">
    <mat-card class="mb-3">
      <mat-card-header>
        <mat-card-title>
          <h4 style="display: inline;">API Settings</h4>
          <p style="display: inline; margin-left: 10px"><i>Premium</i></p>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="apiProviderSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="apiProviderSelection">API Provider</mat-label>
            <mat-select [(ngModel)]="channelInfo.service" name="providerSelect" id="apiProviderSelection"
              [disabled]="!(guild.is_admin) || !(guild.premium_lv > 1)" (selectionChange)="setAPIProvider($event)">
              <mat-option value="Invalid">None</mat-option>
              <mat-option value="GitHub">GitHub</mat-option>
              <mat-option value="GitLab">GitLab</mat-option>
            </mat-select>
            <mat-hint align="end">NOTE: Changing this will reset all other API settings.</mat-hint>
          </mat-form-field>
        </form>
        <ng-container *ngIf="guild.premium_lv > 1">
          <ng-container *ngIf="channelInfo.service === 'GitHub'">
            <h6>GitHub Settings</h6>
          </ng-container>
          <ng-container *ngIf="channelInfo.service === 'GitLab'">
            <h6>GitLab Settings</h6>
          </ng-container>
          <!-- If a service is set, display the settings-->
          <ng-container *ngIf="channelInfo.service && channelInfo.service !== 'Invalid'">
            <form class="apiTokenSettings">
              <mat-form-field class="full-width-container">
                <mat-label for="apiToken">API Token</mat-label>
                <input matInput #apiToken type="password" name="apiToken" id="apiToken" [disabled]="!(guild.is_admin)"
                  [value]="channelInfo.info?.token" (change)="setAPIToken($event.target)" placeholder="API Token">
                <mat-hint align="end">For security reasons, you can not reveal your API token.</mat-hint>
              </mat-form-field>
            </form>
            <form class="apiProjectIDSettings">
              <mat-form-field class="full-width-container">
                <mat-label for="apiProjectID">Project ID</mat-label>
                <input matInput #apiUrl type="text" name="apiProjectID" id="apiProjectID" [disabled]="!(guild.is_admin)"
                  [value]="channelInfo.info?.project_id" (change)="setAPIProjectID($event.target)"
                  placeholder="Project ID">
              </mat-form-field>
            </form>
            <form class="apiEndpointSettings">
              <mat-form-field class="full-width-container">
                <mat-label for="apiEndpoint">API Endpoint</mat-label>
                <input matInput #apiUrl type="text" name="apiEndpoint" id="apiEndpoint" [disabled]="true"
                  [value]="channelInfo.info?.endpoint" placeholder="API Endpoint">
                <mat-hint align="end">This setting can not be changed at the moment.</mat-hint>
              </mat-form-field>
            </form>
            <div class="row justify-content-end">
              <div class="col-12 col-md-4 float-right text-right">
                <button mat-button class="" mat-raised-button color="primary" [disabled]="testAPIButtonDisabled"
                  (click)="testAPI()">Test API</button>
              </div>
            </div>
            <!-- Labels -->
            <form class="labelsSettings">
              <div *ngFor="let label of labels; let i = index" class="label-row">
                <div class="row pr-3">
                  <div class="col-12 col-md-4 pr-0">
                    <mat-form-field class="full-width-container">
                      <mat-label for="labelName">Label Name</mat-label>
                      <input matInput type="text" name="labelName{{i}}" id="labelName{{i}}" [(ngModel)]="label.label"
                        placeholder="Label Name" (input)="checkLabelChanges()">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-3 pr-0 mb-2">
                    <mat-form-field class="full-width-container">
                      <mat-label for="apply_option">Apply Option</mat-label>
                      <mat-select [(ngModel)]="label.apply_option" name="apply_option{{i}}" id="apply_option{{i}}"
                        (selectionChange)="setApplyOption($event, i)">
                        <mat-option value="upvotes">Apply Above Upvotes</mat-option>
                        <mat-option value="role">Apply If Created By Role</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- Conditional rendering based on selected option -->
                  <div *ngIf="label.apply_option === 'upvotes'" class="col-12 col-md-3 pr-0">
                    <mat-form-field class="full-width-container">
                      <mat-label for="upvotes">Apply Above Upvotes</mat-label>
                      <input matInput type="number" (keypress)="validateNo($event)" name="upvotes{{i}}"
                        id="upvotes{{i}}" [(ngModel)]="label.upvotes" inputmode="numeric" pattern="[0-9]*"
                        placeholder="Apply Above Upvotes" (input)="checkLabelChanges()">
                      <mat-hint [ngClass]="{'hide-on-small-screen': true}" align="end">Apply label if submission has
                        more than X up votes.</mat-hint>
                    </mat-form-field>
                  </div>
                  <div *ngIf="label.apply_option === 'role'" class="col-12 col-md-3 pr-0">
                    <mat-form-field class="full-width-container">
                      <mat-label for="LabelRoleSelection">User Role</mat-label>
                      <mat-select [(ngModel)]="label.role_ids" name="labelRole{{i}}"
                        (selectionChange)="checkLabelChanges()" multiple>
                        <ng-container *ngFor="let role of channelInfo.roles">
                          <ng-container *ngIf="role.id !== ''">
                            <mat-option [value]="role.id">
                              {{ role.name }}
                            </mat-option>
                          </ng-container>
                        </ng-container>
                      </mat-select>
                      <mat-hint [ngClass]="{'hide-on-small-screen': true}" align="end">
                        Apply label if submission was created by a user with these roles.
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-2 pr-0">
                    <button mat-icon-button color="warn" *ngIf="i !== 0" (click)="removeLabel(i)">
                      <fa-icon [icon]="faIcons.trash" class="m-auto"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
              <button mat-icon-button color="primary" (click)="addLabel()">
                <fa-icon [icon]="faIcons.add" class="m-auto">Add Label</fa-icon>
              </button>
            </form>
            <!-- Save button -->
            <div class="row justify-content-end">
              <div class="col-12 col-md-4 float-right text-right">
                <button mat-button class="" mat-raised-button color="primary" [disabled]="labelSaveButtonDisabled"
                  (click)="saveLabels()">Save</button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>