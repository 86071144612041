import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { BotGuild, ChannelConfig, CustomEmoji, Label } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { GuildService } from 'src/app/services/guild.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';


import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Validator function to check for overlapping ranges
export function noOverlapValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formArray = control as FormArray;
    const ranges = formArray.value;

    for (let i = 0; i < ranges.length; i++) {
      for (let j = i + 1; j < ranges.length; j++) {
        if (
          (ranges[i].range_start <= ranges[j].range_end && ranges[i].range_end >= ranges[j].range_start) ||
          (ranges[j].range_start <= ranges[i].range_end && ranges[j].range_end >= ranges[i].range_start)
        ) {
          return { overlap: true };
        }
      }
    }

    return null; // No overlap
  };
}

// Validator function to check that range_end is greater than or equal to range_start
export function rangeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const group = control as FormGroup;
    const start = group.get('range_start')?.value;
    const end = group.get('range_end')?.value;

    if (start != null && end != null) {
      if (end < start) {
        group.get('range_end')?.setErrors({ rangeInvalid: true });
        return { rangeInvalid: true };
      } else {
        // Clear the error if validation passes
        const errors = group.get('range_end')?.errors;
        // This is a bit of a mess. We need to remove the rangeInvalid error,
        // but we can not setErrors to null always because we might have nothing
        // in the input field. If we set it to null, the "range is required" error
        // is not shown.
        if (errors) {
          delete errors['rangeInvalid'];
          if (Object.keys(errors).length === 0) {
            group.get('range_end')?.setErrors(null);
          } else {
            group.get('range_end')?.setErrors(errors);
          }
        }
        return null;
      }
    }
    return null;
  };
}


@Component({
  selector: 'app-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.css'],
})
export class ChannelCardComponent implements OnInit {
  faIcons = {
    trash: faTrash,
    add: faPlus,
    plus: faPlus,
    minus: faMinus,
  };

  guildId: string;
  submissionGroupId: string;
  guild: BotGuild;

  testAPIButtonDisabled: boolean = true;

  labels: Label[] = []; // Array to store labels
  originalLabels: any[] = [];
  labelSaveButtonDisabled: boolean = true;

  colorForm: FormGroup;
  initialColors = [];
  hasGapWarning: boolean = false;
  has_premium_and_is_organizer: boolean = false;

  @Input() channelInfo$: Observable<ChannelConfig>;
  @Input() guildInfo$: Observable<BotGuild>;
  @Input() customEmojis$: Observable<CustomEmoji[]>;

  constructor(
    private route: ActivatedRoute,
    private guildService: GuildService,
    private botService: BotResourceService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.guildInfo$ = this.guildService
      .getGuildData(this.guildId)
      .pipe(shareReplay(1));
    this.guildInfo$.subscribe((guild) => {
      this.guild = guild;
      // Angular reuses the component when navigating between routes within the same component.
      // This means that the component is not destroyed and recreated, so ngOnInit is not called again.
      // We therefore need to subscribe to the paramMap observable and update the component when the route changes.
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            this.submissionGroupId = params.get('groupId');
            this.channelInfo$ = this.botService
              .getGuildGroup(this.guildId, this.submissionGroupId)
              .pipe(
                map((data) => {
                  // Convert the color format from "0x5865F2" to "#5865F2"
                  data.colors.forEach((color) => {
                    color.color = color.color.replace('0x', '#');
                  });

                  this.initialColors = JSON.parse(JSON.stringify(data.colors)); // Store initial state
                  this.createForm(data.colors);

                  this.colorForm.valueChanges.subscribe(() => {
                    this.checkColorChanges();
                  });
                  this.colorForm.valueChanges.subscribe(() => {
                    this.checkForGaps();
                  });
                  this.updateFieldAccessibility();

                  return data;
                }),
                shareReplay(1)
              );
            return of(null);
          })
        )
        .subscribe(() => {
          this.testAPIButtonDisabled = this.testAPIisDisabled();
          this.channelInfo$.subscribe((channelInfo) => {
            this.labels = channelInfo.labels;
            if (this.labels.length === 0) {
              this.addLabel();
            }
            this.originalLabels = this.labels.map((label) => ({ ...label }));
            this.has_premium_and_is_organizer = this.guild.premium_lv >= 1 && (this.guild.is_admin || channelInfo.has_mod_role)
            this.updateFieldAccessibility();
          });
          // console.log(
          //   this.channelInfo$.subscribe((channel) => console.log(channel))
          // );
          // console.log(this.guildId)
          // console.log(this.channelInfo$.subscribe(channel => console.log(channel)))
          // console.log(this.guild)
        });
    });
    this.customEmojis$ = this.botService.getGuildEmoji(this.guildId).pipe(
      shareReplay(1)
    );
    this.customEmojis$.subscribe(customEmojis => {
      // iterate over custom emojis and make the string `name` a list with a single entry
      customEmojis.forEach(customEmoji => {
        customEmoji.shortNames = [customEmoji.name, customEmoji.full_id];
      });
    });

  }

  createForm(colors) {
    const colorGroups = colors.map(color => this.fb.group({
      range_start: [color.range_start, [Validators.required, Validators.max(2147483647)]],
      range_end: [color.range_end, [Validators.required, Validators.max(2147483647)]],
      color: [color.color, Validators.required]
    }, { validators: rangeValidator() })); // Apply the custom range validator here

    this.colorForm = this.fb.group({
      colors: this.fb.array(colorGroups, noOverlapValidator()) // Existing overlap validator
    });
  }


  get colors() {
    return this.colorForm.get('colors') as FormArray;
  }

  checkColorChanges() {
    // If length changed return true right away
    if (this.colors.length !== this.initialColors.length) {
      return true;
    }

    const hasChanged = !this.colors.controls.every((control, index) => {
      const initialColor = this.initialColors[index];
      if (this.guild.premium_lv < 1) {
        return control.value.color === initialColor?.color;
      } else {
        return control.value.range_start === initialColor?.range_start &&
          control.value.range_end === initialColor?.range_end &&
          control.value.color === initialColor?.color;
      }
    });

    return hasChanged;
  }

  onColorSubmit() {
    if (this.checkColorChanges()) {
      const formValue = this.colorForm.getRawValue();

      // Extract the colors array from the form value
      const colors = formValue.colors.map(group => ({
        range_start: group.range_start,
        range_end: group.range_end,
        color: group.color
      }));

      // Prepare the JSON object to a string
      const payload = JSON.stringify(colors);

      this.botService.sendSubmissionGroupCommand(
        this.guildId,
        'submission_channel color set',
        payload,
        this.submissionGroupId,
        () => {
          this.initialColors = JSON.parse(JSON.stringify(colors));
        }
      );
    }
  }

  updateFieldAccessibility() {
    this.colors.controls.forEach(group => {
      const rangeStartControl = group.get('range_start');
      const rangeEndControl = group.get('range_end');

      if (!this.has_premium_and_is_organizer) {
        rangeStartControl.disable();
        rangeEndControl.disable();
      } else {
        rangeStartControl.enable();
        rangeEndControl.enable();
      }
    });
  }

  checkForGaps() {
    const ranges = this.colorForm.get('colors')?.value;

    // Sort the ranges by start values
    const sortedRanges = ranges.sort((a, b) => a.range_start - b.range_start);

    for (let i = 0; i < sortedRanges.length - 1; i++) {
      const currentEnd = sortedRanges[i].range_end;
      const nextStart = sortedRanges[i + 1].range_start;

      if (nextStart > currentEnd + 1) {
        this.hasGapWarning = true;
        return;
      }
    }

    this.hasGapWarning = false;
  }


  addLimit() {
    // Get the last entry in the form array
    const lastEntry = this.colors.controls[this.colors.length - 1];
    const lastRangeEnd = lastEntry ? lastEntry.get('range_end')?.value : 0;

    // Calculate the new range_start and range_end
    const newRangeStart = lastRangeEnd != null ? lastRangeEnd + 1 : 0;
    const newRangeEnd = newRangeStart; // Default to the same value, you can adjust this if needed

    // Create a new limit with calculated values
    const newLimit = this.fb.group({
      range_start: [newRangeStart, [Validators.required, Validators.max(2147483647)]],
      range_end: [newRangeEnd, [Validators.required, Validators.max(2147483647)]],
      color: ['#ffffff', Validators.required]
    }, { validators: rangeValidator() }); // Apply the custom range validator here

    // Add the new limit to the form array
    this.colors.push(newLimit);
  }


  removeLimit() {
    if (this.colors.length > 1) {
      this.colors.removeAt(this.colors.length - 1);
    }
  }

  handleEmojiSelection(emoji_type: string, emoji: string): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      emoji_type,
      emoji,
      this.submissionGroupId
    );
  }

  setVoteRole(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'submission_channel vote_role set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId
    );
  }

  setPingRole(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'submission_channel ping_role set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId
    );
  }

  toggleThread(): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'submission_channel thread',
      '',
      this.submissionGroupId
    );
  }

  toggleOnlyThreadCreator(): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'review_channel toggle only_thread_creator',
      '',
      this.submissionGroupId
    );
  }

  setCloseChannel(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'closed_channel id set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId
    );
  }

  setReviewChannel(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'review_channel id set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId
    );
  }

  setFinishBehavior(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'submission_channel finish_behavior set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId
    );
  }

  setDenyChannel(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'denied_channel id set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId
    );
  }

  setAPIProvider(eventTarget: EventTarget): void {
    // Set the api_provider. If it works, update `channelInfo.api_provider` with the set value.
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'api_service set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId,
      () => {
        this.channelInfo$.subscribe((channelInfo) => {
          channelInfo.service = (eventTarget as HTMLInputElement).value;
          channelInfo.info = {
            token: '',
            project_id: '',
            endpoint:
              channelInfo.service === 'GitLab'
                ? 'https://gitlab.com/api/v4'
                : 'https://github.example.com/api/v4',
          };
          this.testAPIisDisabled();
        });
      }
    );
  }

  setAPIToken(eventTarget: EventTarget): void {
    // Set the api_provider. If it works, update `channelInfo.api_provider` with the set value.
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'api_token set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId,
      () => {
        this.channelInfo$.subscribe((channelInfo) => {
          channelInfo.info.token = (eventTarget as HTMLInputElement).value;
          this.testAPIisDisabled();
        });
      }
    );
  }

  setAPIProjectID(eventTarget: EventTarget): void {
    // Set the api_provider. If it works, update `channelInfo.api_provider` with the set value.
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'api_project_id set',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId,
      () => {
        this.channelInfo$.subscribe((channelInfo) => {
          channelInfo.info.project_id = (eventTarget as HTMLInputElement).value;
          this.testAPIisDisabled();
        });
      }
    );
  }

  testAPIisDisabled() {
    this.channelInfo$.subscribe((channelInfo) => {
      if (!channelInfo.service) {
        this.testAPIButtonDisabled = true;
      }
      if (channelInfo.service === 'GitLab') {
        if (
          !channelInfo.info.token ||
          !channelInfo.info.project_id ||
          !channelInfo.info.endpoint
        ) {
          this.testAPIButtonDisabled = true;
        } else {
          this.testAPIButtonDisabled = false;
        }
      }
      if (channelInfo.service === 'GitHub') {
        if (
          !channelInfo.info.token ||
          !channelInfo.info.project_id ||
          !channelInfo.info.endpoint
        ) {
          this.testAPIButtonDisabled = true;
        } else {
          this.testAPIButtonDisabled = false;
        }
      }
    });
    return true;
  }

  getAPIUrl(): string {
    let api_url = '';
    this.channelInfo$.subscribe((channelInfo) => {
      if (channelInfo.service === 'GitLab') {
        api_url =
          channelInfo.info.endpoint +
          '/projects/' +
          channelInfo.info.project_id +
          '/issues';
      }
      if (channelInfo.service === 'GitHub') {
        api_url =
          channelInfo.info.endpoint +
          '/projects/' +
          channelInfo.info.project_id +
          '/issues';
      }
    });
    return api_url;
  }

  getAPIToken() {
    this.channelInfo$.subscribe((channelInfo) => {
      return channelInfo.info.token;
    });
  }

  testAPI() {
    // Disable the button while the request is being made
    this.testAPIButtonDisabled = true;

    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'api_endpoint test',
      '',
      this.submissionGroupId,
      () => {
        this.testAPIButtonDisabled = false;
      },
      () => {
        this.testAPIButtonDisabled = false;
      }
    );
  }

  addLabel() {
    this.labels.push({ label: '', upvotes: 0, apply_option: 'upvotes', role_ids: [] });
    this.checkLabelChanges();
  }

  removeLabel(index: number) {
    this.labels.splice(index, 1);
    this.checkLabelChanges();
  }

  checkLabelChanges(): void {
    if (this.labels.length !== this.originalLabels.length) {
      this.labelSaveButtonDisabled = false;
      return;
    }
    this.labelSaveButtonDisabled = this.labels.every((label, index) => {
      const labelEqual = label.label === this.originalLabels[index].label;
      const upvotesEqual = label.upvotes === this.originalLabels[index].upvotes;
      const applyOptionEqual = label.apply_option === this.originalLabels[index].apply_option;
      const roleIdsLengthEqual = label.role_ids?.length === this.originalLabels[index].role_ids?.length;

      const roleIdsEqual = roleIdsLengthEqual && label.role_ids.every((roleId, roleIndex) => {
        const roleEqual = roleId === this.originalLabels[index].role_ids[roleIndex];
        // console.log(`Role comparison at index ${roleIndex}: ${roleEqual}`);
        return roleEqual;
      });

      // console.log(`Label comparison at index ${index}:`, {
      //   labelEqual,
      //   upvotesEqual,
      //   applyOptionEqual,
      //   roleIdsLengthEqual,
      //   roleIdsEqual
      // });

      return labelEqual && upvotesEqual && applyOptionEqual && roleIdsEqual;
    });

  }

  setLabelRoles(role_ids: string[], index: number) {
    this.labels[index].role_ids = role_ids;
    this.checkLabelChanges();
  }

  setApplyOption(eventTarget: EventTarget, index: number) {
    if ((eventTarget as HTMLInputElement).value === 'upvotes') {
      this.labels[index].role_ids = [];
    } else {
      // find and apply role that is "@everyone" in channelInfo$ roles
      this.labels[index].upvotes = 0;
      this.channelInfo$.subscribe(channelInfo => {
        const role = channelInfo.roles.find(role => role.name === '@everyone');
        if (role) {
          this.labels[index].role_ids = [role.id];
        }
      });
    }
    this.checkLabelChanges();
  }

  saveLabels() {
    // Check if the labels are valid
    if (
      this.labels.some(
        (label) => label.label.trim().length === 0 && label.upvotes !== 0
      )
    ) {
      this.toastr.error('Labels name cannot be empty', 'Error', {
        timeOut: 2000,
      });
      return;
    }

    // Disable the button while the request is being made
    this.labelSaveButtonDisabled = true;
    // Send this.labels to backend
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'api_labels set',
      this.labels,
      this.submissionGroupId,
      () => {
        this.labelSaveButtonDisabled = false;
        this.originalLabels = this.labels.map((label) => ({ ...label }));
        this.checkLabelChanges();
      },
      () => {
        this.labelSaveButtonDisabled = false;
      }
    );
  }

  validateNo(e): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  setSubmissionGroupName(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'submission_group group_name',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId,
      () => {
        let current_collection_name = '';
        this.channelInfo$.subscribe(
          (channelInfo) => (
            (current_collection_name = channelInfo.collection_name),
            (channelInfo.collection_name = (
              eventTarget as HTMLInputElement
            ).value)
          )
        );
        this.guildInfo$.subscribe(
          (guildInfo) => (
            // find current_collection_name in guildInfo.channels and replace the name
            guildInfo.channels.map((channel) => {
              if (channel.name === current_collection_name) {
                channel.name = (eventTarget as HTMLInputElement).value
              }
            })
          ))
      }
    );
  }

  setModRole(eventTarget: EventTarget): void {
    this.botService.sendSubmissionGroupCommand(
      this.guildId,
      'submission_group mod_role',
      (eventTarget as HTMLInputElement).value,
      this.submissionGroupId
    );
  }
}
