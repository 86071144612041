import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from './app.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import { HomeComponent } from './pages/home/home.component';
import * as env from '../environments/environment';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GuildCardsComponent } from './pages/guild-selection/guild-cards/guild-cards.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FindEventPipe } from './pipes/find-event.pipe';
import { PremiumComponent } from './pages/premium/premium.component';
import { StatisticsComponent } from './pages/home/statistics/statistics.component';
// import { NgxObserveModule } from 'ngx-observe';
import { NgxPayPalModule } from 'ngx-paypal';
import { GuildSelectionComponent } from './pages/guild-selection/guild-selection.component';
// import { DarkModeComponent } from './dark-mode/dark-mode.component';
import { GuildDashboardComponent } from './pages/guild-dashboard/guild-dashboard.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule} from '@angular/material/button';
import { MatIconModule} from '@angular/material/icon';
import { MatDividerModule} from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';import { OverviewCardComponent } from './pages/guild-dashboard/overview-card/overview-card.component';
import { ServerCardComponent } from './pages/guild-dashboard/server-card/server-card.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChannelCardComponent } from './pages/guild-dashboard/channel-card/channel-card.component';
import { DialogElementsExampleDialog, FastspringCardComponent } from './pages/guild-dashboard/premium/premium-card.component';
import { NgxColorsModule} from 'ngx-colors';
import { DurationPipe } from './pipes/duration.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { EmojiPickerButtonComponent } from './components/emoji-picker-button/emoji-picker-button.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatPaginatorModule } from '@angular/material/paginator';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TermsComponent,
    PrivacyComponent,
    GuildCardsComponent,
    FindEventPipe,
    PremiumComponent,
    StatisticsComponent,
    GuildSelectionComponent,
    // DarkModeComponent,
    GuildDashboardComponent,
    OverviewCardComponent,
    ServerCardComponent,
    ChannelCardComponent,
    FastspringCardComponent,
    DurationPipe,
    EmojiPickerButtonComponent,
    DialogElementsExampleDialog,
  ],
  imports: [
    // MatDialogTitle,
    // MatDialogContent,
    // MatDialogActions,
    // MatDialogClose,
    MatTooltipModule,
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [env.environment.baseUrlDiscord, 'api'],
        sendAccessToken: true,
      },
    }),
    ToastrModule.forRoot(),
    FontAwesomeModule,
    // NgxObserveModule,
    NgxPayPalModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTreeModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    NgxColorsModule,
    PickerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [EmojiPickerButtonComponent]

})
export class AppModule {}
