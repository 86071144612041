import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import { faCalendarCheck, faCheck, faList, faRepeat, faUsers, faXmark, faInfinity, faLockOpen, faLock } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { BotGuild, GuildStatistics, GuildSubmission, MostVotedSubmissions, ServerSubmissions } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { GuildService } from 'src/app/services/guild.service';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';


export class CustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = $localize`First page`;
  itemsPerPageLabel = $localize`Items per page:`;
  lastPageLabel = $localize`Last page`;
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`Page ${page + 1} of ${amountPages}`;
  }
}

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: CustomPaginatorIntl}],
})
export class OverviewCardComponent implements OnInit {
  faIcons = {
    users: faUsers,
    calendarCheck: faCalendarCheck,
    cross: faXmark,
    check: faCheck,
    list: faList,
    calendaPlus: faCalendarPlus,
    repeat: faRepeat,
    infinity: faInfinity,
    open: faLockOpen,
    locked: faLock,
  };

  @Input() statistics$: Observable<GuildStatistics>;
  @Input() submissions$: Observable<GuildSubmission>;
  guildId: string;
  guild: BotGuild;
  pageSize: number = 10;
  currentPage: number = 1;

  constructor(
    private route: ActivatedRoute,
    private botService: BotResourceService,
    private guildService: GuildService
  ) {}

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.statistics$ = this.botService.getGuildStatistics(this.guildId).pipe(shareReplay(1));
    this.fetchSubmission();

    this.guildService.getGuildData(this.guildId).subscribe((guild) => {
      this.guild = guild;
    });
  }

  fetchSubmission() {
    const previousSubmissions$ = this.submissions$;

    this.submissions$ = this.botService
      .getGuildSubmission(this.guildId, this.currentPage, this.pageSize)
      .pipe(
        map((statistics) => {
          // Sort by upvote_count primarily, and by submission_id secondarily
          statistics.most_voted_submissions = Object.values(
            statistics.most_voted_submissions
          ).sort((a: MostVotedSubmissions, b: MostVotedSubmissions) => {
            // Compare upvote_count first
            if (b.upvote_count !== a.upvote_count) {
              return b.upvote_count - a.upvote_count;
            }
            // If upvote_count is equal, compare submission_id
            return a.submission_id.localeCompare(b.submission_id);
          });

          // Format the creation date for display
          statistics.most_voted_submissions.forEach((submission) => {
            submission.created_at_date = new Date(
              submission.created_at
            ).toLocaleDateString(navigator.language || 'en-US');
          });

          return statistics;
        }),
        catchError((error) => {
          // Return the previous submissions observable or an empty observable
          return previousSubmissions$ || [];
        }),
        shareReplay(1)
      );
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.fetchSubmission();
  }

  getClosedCountInfo(stats: ServerSubmissions) {
    return `
    Done: ${stats.done_submissions}
    Closed: ${stats.closed_submissions}
    Denied: ${stats.denied_submissions}`;
  }
}
